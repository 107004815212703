'use client';

import { useRouter } from 'next/navigation';

import { useLocale } from 'app/lib/hooks';
import { getTranslations } from 'app/lib/utils.translations';

export default function BackButton() {
  const router = useRouter();
  const locale = useLocale();
  const t = getTranslations(locale);

  return <button onClick={router.back}>{t('back')}</button>;
}
