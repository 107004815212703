import { useParams } from 'next/navigation';
import { isString } from 'lodash';

import { SiteLocale } from '@types';
import { LOCALES } from 'app/constants';

export const useLocale = (): SiteLocale => {
  const params = useParams();
  const locale =
    isString(params?.locale) && LOCALES.find((l) => `${l}` === params.locale);

  return locale || SiteLocale.EN;
};
